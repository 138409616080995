import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useHeaderHooks=()=>{
    const [IsOpen, SetIsOpen]=useState(false);
    const navigate = useNavigate();
    /**
     * to display popup
     */

     const toggleNote=()=>{
        SetIsOpen(!IsOpen);

    }

    const logoutHandler =()=>{
        navigate('/');
    }
   
  
  
  
    return{
        IsOpen,
        toggleNote,
        logoutHandler
    }
}
export default useHeaderHooks;



