import React from "react";
import useHomeScreenHook from "./useHomeScreenHook";
import { withTranslation } from "react-i18next";
import "./Home.css";
import { back } from "../../../assets/images/images";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/footer";
import Popup from "../popup/popup";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

// import DateTimePicker from 'react-datetime-picker';
// import DatePicker from "react-datepicker";

const HomeScreen = (props) => {
  const { t } = props;
  //  <Popup response={response}/> 
  const {
    response,
    togglePopup,
    IsOpen,
    show,
    image,
    requestorName,
    empCode,
    requestorAddress,
    requestorContact,
    guestName,
    guestAddress,
    guestContact,
    guestRelationship,
    numOfDays,
    purposeOfVisit,
    includeGst,
    datetimevalue,
    datetimevalue1,
    gst,
    submitted,
    department,
    institution,
    email,
    numOfRoom,
    numOfGuest,
    role_type,
    InputNumber,
    selectedFile,
    role_type_get,
    handleDepartment,
    handleInstitution,
    handleEmail,
    handleSubmit,
    UploadHandler,
    handleRequestorChange,
    handleEmpcode,
    handleRequestoAddress,
    handleRequestorContact,
    handleGuestName,
    handleGuestAddress,
    handleGuestContact,
    handleGuestRelationship,
    handleArrivalDate,
    handleDepartureDate,
    handleNumOfDays,
    handleNumOfGuests,
    handleNumOfRooms,
    handlePurposeOfVisit,
    gstOnchange,
    onChangeImageHandler,
    changeAcceptCheckboxHandler
  } = useHomeScreenHook();
  console.log(role_type);
  // const { handleLogout } = useHomeScreenHook();
  // const { formHandler } = useHomeScreenHook();
  // const { show } = useHomeScreenHook();
  // const { IsOpen} = useHomeScreenHook();
  // const { Popup} = useHomeScreenHook();
  /**
   * import header component
   */
  const headerComponent = () => {
    return (
      <Header />
    )
  }
  /**
   * main screen design
   */
  const backgroundImage = () => {
    return (
      <div class="back flt">
        <img src={back}></img>
      </div>
    )
  }

  const backHeading = () => {
    return (
      <div class="back_head flt">
        MAHE Guest House Reservation Form
      </div>

    )
  }
  const formHandling1 = () => {
    return (
      <div class="col-sm-4">
        <div class="form1 flt">
          <form>
            <h3>Requestor Details </h3>
            <div class="form-group flt">
              <label>Name of the Requestor <span className="star">*</span></label>
              <input type="text" name="requestorName" readOnly={true} class={"form-control" + (submitted && !requestorName ? ' is-invalid' : '')} id="cname" placeholder="Enter the Requestor Name and Institution" value={requestorName} onChange={handleRequestorChange} />
              {submitted && !requestorName &&
                <div className="invalid-feedback">Requestor Name Required</div>
              }
            </div>
            {role_type_get === 'STAFF' &&
              <div class="form-group flt">
                <label>Name of the Department <span className="star">*</span></label>
                <input type="text" name="department" readOnly={true} class={"form-control" + (submitted && !department ? ' is-invalid' : '')} id="cname" placeholder="Enter the department" value={department} onChange={handleDepartment} />
                {submitted && !department &&
                  <div className="invalid-feedback">Department Name Required</div>
                }
              </div>}
            <div class="form-group flt">
              <label>Name of the Institution <span className="star">*</span></label>
              <input type="text" name="institution" readOnly={true} class={"form-control" + (submitted && !institution ? ' is-invalid' : '')} id="cname" placeholder="Enter the Institution" value={institution} onChange={handleInstitution} />
              {submitted && !institution &&
                <div className="invalid-feedback">Institution Name Required</div>
              }
            </div>

            <div class="form-group flt">
              <label>Requestor Email<span className="star">*</span></label>
              <input type="text" name="requestorName" class={"form-control" + (submitted && !email ? ' is-invalid' : '')} id="cname" placeholder="Enter the Requestor Email" value={email} onChange={handleEmail} />
              {submitted && !email &&
                <div className="invalid-feedback">Requestor Email Required</div>
              }
            </div>
            <div class="form-group flt">
              <label>Requestor Contact Number <span className="star">*</span></label>
              <input type="text" name="requestorContact" readOnly={true} class={"form-control" + (submitted && !requestorContact ? ' is-invalid' : '')} id="cnum" placeholder="Enter Requestor Contact
              Number" value={requestorContact} onChange={handleRequestorContact} />
              {submitted && !requestorContact &&
                <div className="invalid-feedback">Requestor Contact Number Required</div>
              }
            </div>
            <div class="form-group flt">
              <label>Employee Code / Roll
                Number</label>
              <input type="text" name="empCode" readOnly={true} class="form-control" id="ccode" placeholder="Enter Employee Code / Roll
              Number" value={empCode} onChange={handleEmpcode} />
              {/* {submitted && !empCode &&
                <div className="invalid-feedback">Employee Code / Roll Required</div>
              } */}
            </div>
            <div class="form-group flt">
              <label>Requestor Address</label>
              <textarea rows="4" name="requestorAddress" class="form-control" placeholder="Type Requestor Address" value={requestorAddress} onChange={handleRequestoAddress} >
                {/* {submitted && !requestorAddress &&
                  <div className="invalid-feedback">Requestor Address Required</div>
                } */}
              </textarea>
            </div>
            <div class="form-group checkbox  flt">
              <input type="checkbox"
                // onClick={formHandler}
                id="check"
                // class={"form-control" + (submitted && !includeGst ? ' is-invalid' : '')}
                checked={includeGst}
                // onClick={changeAcceptCheckboxHandler}
                onChange={(e) => {
                  gstOnchange({
                    includeGst: e.target.checked ? 1 : 0
                  });
                }}
              />
              <label>You will need to click the checkbox if you would like to include <span>GST</span> </label>
              {/* {submitted && !includeGst &&
                <div className="invalid-feedback">GST Required</div>
              } */}
            </div>
            {includeGst === 1 &&
              <div className='flt'>
                <div class="form-group GST flt">
                  <label>GST Number <span className="star">*</span></label>
                  <input type="text" name="gstNumber" class={"form-control" + (submitted && !gst?.gstNumber ? ' is-invalid' : '')} id="gstnum" placeholder="Enter GST number"
                    value={gst?.gstNumber}
                    onChange={(e) => {
                      gstOnchange({
                        gst: {
                          ...gst,
                          gstNumber: e.target.value
                        }
                      });
                    }} />
                  {submitted && !gst?.gstNumber &&
                    <div className="invalid-feedback">GST Number Required</div>
                  }
                </div>
                <div class="form-group flt">
                  <label>Company Name <span className="star">*</span></label>
                  <input type="text" name="gstCompany" class={"form-control" + (submitted && !gst?.gstCompany ? ' is-invalid' : '')} id="comname" placeholder="Enter Company Name"
                    // value={gstCompany}  
                    value={gst?.gstCompany}
                    onChange={(e) => {
                      gstOnchange({
                        gst: {
                          ...gst,
                          gstCompany: e.target.value
                        }
                      });
                    }} />
                  {submitted && !gst?.gstCompany &&
                    <div className="invalid-feedback">GST Company Name Required</div>
                  }
                </div>
                <div class="form-group flt">
                  <label>Address <span className="star">*</span></label>
                  <textarea rows="4" name="gstAddress" class={"form-control" + (submitted && !gst?.gstAddress ? ' is-invalid' : '')} placeholder="Type Address"
                    // value={gstAddress} 
                    value={gst?.gstAddress}
                    onChange={(e) => {
                      gstOnchange({
                        gst: {
                          ...gst,
                          gstAddress: e.target.value
                        }
                      });
                    }}
                  ></textarea>
                  {submitted && !gst?.gstAddress &&
                    <div className="invalid-feedback">GST Address Required</div>
                  }
                </div>
              </div>
            }
          </form>
        </div>
      </div>
    )
  }
  const formmerge = () => {
    return (
      <div className="row">
        <h3>Guest Details</h3>
        {formHandling2()}
        {formHandling3()}
      </div>
    )
  }
  const formHandling2 = () => {
    return (
      <div class="col-sm-6">
        <form>
          <div class="form-group flt">
            <label>Name of the Guest <span className="star">*</span></label>
            <input type="text" name="guestName" class={"form-control" + (submitted && !guestName ? ' is-invalid' : '')} id="name" placeholder="Enter Guest Name" onChange={handleGuestName} value={guestName} />
            {submitted && !guestName &&
              <div className="invalid-feedback">Name of the Guest Required</div>
            }
          </div>

          <div class={"form-group flt" + (submitted && !datetimevalue ? ' is-invalid' : '')}>
            <label>Arrival Date & TIME <span className="star">*</span></label>
            <Datetime
              name="arrivalDate"
              dateFormat="DD/MM/YYYY"
              // timeFormat="true"
              onChange={handleArrivalDate}
              value={new Date(datetimevalue)}
              inputProps={{
                placeholder: "Select Date",
              }}
            />
            {submitted && !datetimevalue &&
              <div className="invalid-feedback">Arrival Date and Time Required</div>
            }
          </div>
          <div class={"form-group flt" + (submitted && !datetimevalue1 ? ' is-invalid' : '')}>
            <label>Departure Date & Time <span className="star">*</span></label>
            <Datetime
              name="departureDate"
              dateFormat="DD/MM/YYYY"
              onChange={handleDepartureDate}
              value={new Date(datetimevalue1)}
              inputProps={{
                placeholder: "Select Date",
              }}
            />
            {submitted && !datetimevalue1 &&
              <div className="invalid-feedback">Departure Date and Time Required</div>
            }
          </div>
          <div class="form-group flt">
              <label>No Of Rooms</label>
              <input type="text" name="numOfRoom" class="form-control" id="room" placeholder="Enter no.of Rooms" onChange={handleNumOfRooms} value={numOfRoom} />
            </div>
            <div class="form-group flt">
              <label>No of Guests</label>
              <input type="text" name="numOfGuest" class="form-control" id="guest" placeholder="Enter no.of Guests" onChange={handleNumOfGuests} value={numOfGuest} />
            </div>
         
          <div class="form-group docment flt" >
            <label>Document</label>
            <select onChange={UploadHandler} className="required" >
              <option value='' key='' selected>Select Document</option>
              <option value="">Aadhaar Card</option>
              <option value="" >PAN Card</option>
              <option value="">Driving License</option>
            </select>
            {show && <input type="file" accept="image/*" placeholder="Upload a file" onChange={onChangeImageHandler} />}
            {/* {submitted && !show &&
              <div className="invalid-feedback">Document Required</div>
            }
            {submitted && show && !selectedFile &&
              <div className="invalid-feedback">File required</div>
            } */}
          </div>
        </form>
      </div>
    )
  }

  const formHandling3 = () => {
    return (
      <>
        <div class="col-sm-6 reln">
          <form>
            <div class="form-group flt">
              <label>Guest Contact Number <span className="star">*</span></label>
              <input type="text" name="guestContact" class={"form-control" + (submitted && !guestContact ? ' is-invalid' : '')} placeholder="Enter Guest Contact Number" onChange={handleGuestContact} value={guestContact} />
              {submitted && !guestContact &&
                <div className="invalid-feedback">Guest Contact Number Required</div>
              }
            </div>
            <div class="form-group flt">
              <label>No. of Days</label>
              <input type="text" name="numOfDays" class="form-control" id="num" placeholder="Enter no.of days" onChange={handleNumOfDays} value={numOfDays} />
              {/* {submitted && !numOfDays &&
                <div className="invalid-feedback">No. of Days Required</div>
              } */}
            </div>

            <div class="form-group flt">
              <label>Relationship <span className="star">*</span></label>
              <input type="text" name="guestRelationship" class={"form-control" + (submitted && !guestRelationship ? ' is-invalid' : '')} id="relation" placeholder="Enter Relationship" value={guestRelationship} onChange={handleGuestRelationship} />
              {submitted && !guestRelationship &&
                <div className="invalid-feedback">Guest Relationship Required</div>
              }
            </div>
            <div class="form-group form_add flt">
            <label>Guest Address</label>
            <textarea rows="4" name="guestAddress" class="form-control" placeholder="Type Guest Address" value={guestAddress} onChange={handleGuestAddress} ></textarea>
            {/* {submitted && !guestAddress &&
              <div className="invalid-feedback">Guest Address Required</div>
            } */}
          </div>
            <div class="form-group form_add flt">
              <label>Purpose of Visit</label>
              <textarea rows="4" name="purposeOfVisit" class="form-control" placeholder="Enter Purpose of Visit" value={purposeOfVisit} onChange={handlePurposeOfVisit}></textarea>
              {/* {submitted && !purposeOfVisit &&
                <div className="invalid-feedback">Purpose of Visit Required</div>
              } */}
            </div>
          </form>
        </div>
        {/* <div class="col-sm-6">
          <div class="form-group flt">
            <label>Payment by</label>
            <select class="form-control">
              <option>MAHE</option>
            </select>
          </div>
        </div> */}
        <div class="sub_txt flt">
          <button onClick={handleSubmit}> SUBMIT REQUEST </button>
        </div>
      </>
    )
  }
  //   const formHandling4=()=>{
  //     return(
  //      <>
  //       <div class="col-sm-6">
  //       <div class="form-group flt">
  //           <label>Payment by</label>
  //           <select class="form-control">
  //               <option>MAHE</option>
  //           </select>
  //       </div>
  //   </div>
  //   <div class="sub_txt flt">
  //       <button onClick={togglePopup}> SUBMIT REQUEST </button>
  //   </div>
  // </>
  //     )
  //   }
  /**
   * popup display
   */
  const popupdisplay = () => {
    return (
      <Popup data={response} handleClose={togglePopup} />
    )
  }
  /**
   * import footer component
   */
  const footerComponent = () => {
    return (
      <Footer />
    )
  }
  return (
    <>
      {headerComponent()}
      {backgroundImage()}
      <div class="form_container flt">
        {backHeading()}
        <div className="row">
          {formHandling1()}
          <div class="col-sm-8">
            <div class="form2 flt">
              {formmerge()}
              {/* {formHandling2()} */}
              {/* {formHandling3()} */}
              {/* {formHandling4()} */}
            </div>
          </div>

        </div>
      </div>
      {IsOpen && popupdisplay()}
      {footerComponent()}
    </>
  );
};
export default withTranslation()(HomeScreen);