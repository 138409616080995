import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./store";
import { alertActions, userLogout } from "./actions";
import Login from "./components/pages/Login/LoginScreen";
import Home from "./components/pages/Home/HomeScreen";
import "bootstrap/dist/css/bootstrap.min.css";

const Main = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const alert = useSelector((state) => state?.alert);
  const user = JSON.parse(localStorage.getItem("state"));
  const isUser = user ? Object.keys(user.userData).length : 0;
  const session_expired = user?.userData?.session_expired;

  useEffect(() => {
    setTimeout(() => dispatch(alertActions.clear()), 3000);
    // eslint-disable-next-line
  }, [alert.message]);

  //session expire
  useEffect(() => {
    if (session_expired) {
      dispatch(alertActions.error("Authentication error..Please login"));
      setTimeout(() => {
        dispatch(userLogout());
        navigate("/");
      }, 100);
    }
    // eslint-disable-next-line
  }, [session_expired]);

  return (
    <div>
      {alert.message && (
        <div className={`alert ${alert.type}`}>{alert.message}</div>
      )}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
};

export default function App() {
  return (
    <Provider store={store}>
      <Main />
    </Provider>
  );
}
