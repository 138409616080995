import "./Login.css";
import React from "react";
import useLoginHooks from "./useLoginScreenHook";
import { Loader } from "../../../assets/images/images";
import { withTranslation } from "react-i18next";
import mahe_logo from "../../../assets/images/mahe-logo.png";

const Login = (props) => {
  const { t } = props;
  const {
    show,
    otp1,
    otp2,
    otp3,
    otp4,
    otp5,
    storeotp,
    otp1valid,
    otp2valid,
    otp3valid,
    otp4valid,
    otp5valid,
    otp1InputRef,
    otp2InputRef,
    otp3InputRef,
    otp4InputRef,
    otp5InputRef,
    loader,
    userName,
    password,
    submitted,
    buttonTxt,
    handleUserIdChange,
    handlePasswordChange,
    enrollment_number,
    handleSubmit,
    handleLogin,
    showOtp,
    submit,
    number,
    verifyOtp,
    resendOtp,
    timeLeft,
    sendLoginOtp,
    handleEnrolnoChange
  } = useLoginHooks(t);

  var validotp1 = otp1valid();
  var validotp2 = otp2valid();
  var validotp3 = otp3valid();
  var validotp4 = otp4valid();

  const renderLoginOtp = () => {
    return (
      <div class="login_container flt">
        <div class="login_box">
          <div class="login_top flt">
            <div class="login_logo">
              <img src={mahe_logo} />
            </div>
            <div class="login_frame">
              <div class="login_head">mahe Guest House Login</div>
              <div class="login_roll_no">Enter your Roll number below</div>
            </div>
          </div>
          <div class="login_btm flt">
            <form>
              <input class="form-control" placeholder="Enter Enrollment Number/Registration No"
                type="text" value={enrollment_number} onChange={handleEnrolnoChange} />
              {showOtp === false && (
                <div className="login_btn flt">
                  <button data-toggle="collapse" href="#collapse1" class="" aria-expanded="true" type="submit" onClick={sendLoginOtp}>Login</button>
                </div>
              )}
              {showOtp === true && (
                <>
                  <div className="resend_otp">
                    <label>Enter OTP</label>
                    <span className="timer">
                      {timeLeft ? "" + timeLeft : ""}
                    </span>
                    {timeLeft === null ? (
                      <span className="resend" onClick={resendOtp}>
                        Resend OTP
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="login_otp flt">
                    <input
                      type="text"
                      placeholder=""
                      name="otp1"
                      className={
                        submit && validotp1 ? "is-invalid" : ""
                      }
                      maxLength={1}
                      value={otp1}
                      onChange={storeotp}
                      ref={otp1InputRef}
                    />
                    <input
                      type="text"
                      placeholder=""
                      name="otp2"
                      className={
                        submit && validotp2 ? "is-invalid" : ""
                      }
                      maxLength={1}
                      value={otp2}
                      onChange={storeotp}
                      ref={otp2InputRef}
                    />
                    <input
                      type="text"
                      placeholder=""
                      name="otp3"
                      className={
                        submit && validotp3 ? "is-invalid" : ""
                      }
                      maxLength={1}
                      value={otp3}
                      onChange={storeotp}
                      ref={otp3InputRef}
                    />
                    <input
                      type="text"
                      placeholder=""
                      name="otp4"
                      className={
                        submit && validotp4 ? "is-invalid" : ""
                      }
                      maxLength={1}
                      value={otp4}
                      onChange={storeotp}
                      ref={otp4InputRef}
                    />
                  </div>
                  <div className="login_btn flt">
                    <button type="submit" onClick={verifyOtp}>
                      Submit
                    </button>
                  </div>
                </>
              )}

            </form>
          </div>
        </div>
      </div>
    );

  }

  return (
    renderLoginOtp()
  );
}

export default withTranslation()(Login);
