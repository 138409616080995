import React from 'react'
import './footer.css'

function Footer() {
    return (
        <>

            <div class="contact flt">

                <ul>
                    <li>
                        manipal.edu, Madhav Nagar, Manipal – 576104, Karnataka, India
                    </li>
                    <li>
                        Phone : 0820-29-23480
                    </li>
                    <li>
                        Email : guesthouse.mahe@manipal.edu
                    </li>
                    <li>
                        NIH Guest House : 0820-29-31640
                    </li>
                </ul>

            </div>

            <div class="footer flt">



                <div class="foot_text flt">
                    Designed By Chipsy IT Services PVT. LTD.
                </div>
            </div>

        </>

    );
}

export default Footer