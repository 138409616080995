import * as axios from "../network/Network";

const config = require("../network/api.json");

class UserService {
 

  static sendotp(enrollment_number) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.sendOtp,
      data: { 
        enrollment_number : enrollment_number,
      },
    });
  }
 
  static verifyotp(enrollment_number,otp) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.verifyOtp,
      data: { enrollment_number,otp},
    });
  }

}
export { UserService };
