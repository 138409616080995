import * as axios from "../network/Network";

const config = require("../network/api.json");

class HomeService {
    // add details submit
    static addRequestorDetails(
        requestor_name,
        institution,
        email,
        employee_code,
        requestor_address,
        requestor_contact_number,
        guest_name,
        guest_address,
        guest_contact_number,
        guest_relationship,
        arrival_date,
        arrival_time,
        departure_date,
        departure_time,
        number_of_days,
        number_of_rooms,
        number_of_guests,
        purpose_of_visit,
        include_gst,
        gst_number,
        gst_address,
        gst_company_name,
        image_file,
        institution_master_id,
        degree_master_id,
        course_master_id,
        degree,
        course,
        role_type,
        department,
        department_master_id
        // phone_no,
        // approved_status
    ) {
        var bodyFormData = new FormData()
        bodyFormData.append("requestor_name", requestor_name)
        bodyFormData.append("institution", institution)
        bodyFormData.append("email", email)
        bodyFormData.append("employee_code", employee_code)
        bodyFormData.append("requestor_address", requestor_address)
        bodyFormData.append("requestor_contact_number", requestor_contact_number)
        bodyFormData.append("guest_name", guest_name)
        bodyFormData.append("guest_address", guest_address)
        bodyFormData.append("guest_contact_number", guest_contact_number)
        bodyFormData.append("guest_relationship", guest_relationship)
        bodyFormData.append("arrival_date", arrival_date)
        bodyFormData.append("arrival_time", arrival_time)
        bodyFormData.append("departure_date", departure_date)
        bodyFormData.append("departure_time", departure_time)
        bodyFormData.append("number_of_days", number_of_days)
        bodyFormData.append("number_of_rooms", number_of_rooms)
        bodyFormData.append("number_of_guests", number_of_guests)
        bodyFormData.append("purpose_of_visit", purpose_of_visit)
        bodyFormData.append("include_gst", include_gst)
        // if (include_gst === 1) {
        bodyFormData.append("gst_number", gst_number)
        bodyFormData.append("gst_company_name", gst_company_name)
        bodyFormData.append("gst_address", gst_address)
        // }
        bodyFormData.append("image_file", image_file)
        bodyFormData.append("role_type", role_type)
        bodyFormData.append("institution_master_id", institution_master_id)
        bodyFormData.append("degree_master_id", degree_master_id)
        bodyFormData.append("course_master_id", course_master_id)
        bodyFormData.append("course", course)
        bodyFormData.append("degree", degree)
        bodyFormData.append("approved_status", 0)
        bodyFormData.append("department", department)
        bodyFormData.append("department_master_id", department_master_id)
        // console.log(role_type);
        const result = axios.appRequest({
            method: axios.POST,
            url: config.user.addRequestDetails,
            data: bodyFormData,
            
        }
        );
        return Promise.resolve(result);
    }

}
export { HomeService };
