import React from "react"
import { popimg } from "../../../assets/images/images";
import usepopupHook from './usepopupHook';
import HomeScreen from "../Home/HomeScreen";
import './popup.css'

const Popup = (props) => {
  const { t } = props;
  const {handleClose}=usepopupHook();
  return (
    <div className='popup-box flt'>
      <div className='box flt'>
        <div className='imgg flt'>
          <img src={popimg} width="100px" height="100px"></img>
        </div>
        <div className='head  flt'><h2>Thanks For Submitting!</h2></div>
        <div className='para flt'><p>Your request is successfully submitted</p></div>
        <div className='para2 flt'><p>For any questions or clarifications, you can reach out to <br></br>
                  {props.data.data.detailsOfAdminUsers}
         </p></div>
       
        <div className='sub_btn flt'><button onClick={handleClose} >Ok</button></div>
      </div> 
      
    </div>
  )
}
export default Popup