import React from 'react'
import { iconn, square } from '../../../assets/images/images'
import './note.css'


const Note = (props) => {
    return (
        <>
            <div className='popup-box flt'>
                <div className='box1 flt'>
                    <div className='row'>
                        <div className='col-sm-6 text'>
                            <span  ><img src={iconn}></img></span>Note
                        </div>
                        <div className='col-sm-6 buttn'>
                            <button onClick={props.handleClose}>X</button>
                        </div>
                    </div>
                    <div className='list flt'>
                        <ul>
                            <li>
                                <span><img src={square}></img></span>Reservation will be subject to availability of rooms.
                            </li>
                            <li> <span><img src={square}></img></span> Reservation form to be submitted one week in advance.
                            </li>
                            <li>
                                <span><img src={square}></img></span> Cancellation must be informed well in advance.
                            </li>
                            <li>
                                <span><img src={square}></img></span>Messing facilities available in NIH food court till 9:30 pm.
                            </li>
                            <li>
                                <span><img src={square}></img></span> Room service not available.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Note