import { useState, useRef,useEffect } from "react";
// import { userLogin } from "../../../actions/index";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { alertActions } from "../../../actions/alert.actions";
import { UserService } from "../../../services";
import {
  userLogin,
} from "../../../actions/UserAction";

const useLoginHooks = (t) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(null);
  const [showOtp, setShowOtp] = useState(false);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const otp1InputRef = useRef(null);
  const otp2InputRef = useRef(null);
  const otp3InputRef = useRef(null);
  const otp4InputRef = useRef(null);
  const otp5InputRef = useRef(null);
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [number,setNumber] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [loader, setLoader] = useState("notset");
  const [show, setShow] = useState(false);
  const[enrollment_number,setEnrollment_number]= useState("");
  const [submit, setSubmit] = useState(false);
  const [buttonTxt, setButtonTxt] = useState('Login')
  /**
   * handle change
   * @param {*} e 
   */

  const handleEnrolnoChange = (e) => {
    const result = e.target.value.replace(/[a-zA-Z0-9]+$./ig, ' ');
    setEnrollment_number(result);
  }

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);


  /**
   * api call
   * @param {*} e 
   */

  const sendLoginOtp = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    if (enrollment_number) {
      UserService.sendotp(enrollment_number).then((res) => {
          if(res?.data.status?.code === 0) {
            setTimeLeft(59);
            setShowOtp(true);
            setSubmitted(false);
            dispatch(alertActions.success(res.data.status.message));
          } else {
            dispatch(alertActions.error(res.data.status.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  }
  const resendOtp = (e) => {
    e.preventDefault();
    setTimeLeft(59);
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    UserService.sendotp(enrollment_number)
      .then((res) => {
        setShowOtp(true);
        dispatch(alertActions.success(res.status.message));
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (otp1 && otp2 && otp3 && otp4 ) {
      let otp = otp1 + otp2 + otp3 + otp4;
      UserService.verifyotp(enrollment_number, otp)
        .then((res) => {
          if (res?.data.status?.code === 0) {  
            setSubmit(false);
            console.log(res.data.data[0].course);
            console.log(res.data.data[0].role_type);
            localStorage.setItem("name", res.data.data[0].name);
            localStorage.setItem("phone_no", res.data.data[0].phone_no);
            localStorage.setItem("institution", res.data.data[0].institution);
            localStorage.setItem("enrollment_number", res.data.data[0].enrollment_number);
            localStorage.setItem("role_type", res.data.data[0].role_type);
            localStorage.setItem("institution_master_id", res.data.data[0].institution_master_id);
            localStorage.setItem("degree_master_id", res.data.data[0].degree_master_id);
            localStorage.setItem("course_master_id", res.data.data[0].course_master_id);
            localStorage.setItem("degree", res.data.data[0].degree);
            localStorage.setItem("course", res.data.data[0].course);
            localStorage.setItem("department", res.data.data[0].department);
            localStorage.setItem("department_master_id", res.data.data[0].department_master_id);
            dispatch(alertActions.success(res.data.status.message));
            navigate('/home');
          } else {
            dispatch(alertActions.error(res.data.status.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };

  const otp1valid = () => {
    if (otp1 === "") {
      return true;
    }
  };
  const otp2valid = () => {
    if (otp2 === "") {
      return true;
    }
  };
  const otp3valid = () => {
    if (otp3 === "") {
      return true;
    }
  };
  const otp4valid = () => {
    if (otp4 === "") {
      return true;
    }
  };

  const storeotp = (e) => {
    if (e.target.name === "otp1") {
      setOtp1(e.target.value.slice(0, 1));
      otp2InputRef.current.focus();
    }
    if (e.target.name === "otp2") {
      setOtp2(e.target.value.slice(0, 1));
      otp3InputRef.current.focus();
    }
    if (e.target.name === "otp3") {
      setOtp3(e.target.value.slice(0, 1));
      otp4InputRef.current.focus();
    }
    if (e.target.name === "otp4") {
      setOtp4(e.target.value.slice(0, 1));
      otp5InputRef.current.focus();
    }
  };

  return {
    loader,
    show,
    sendLoginOtp,
    userName,
    password,
    submitted,
    buttonTxt,
    showOtp,
    submit,
    number,
    verifyOtp,
    resendOtp,
    enrollment_number,
    timeLeft,
    storeotp,
    otp1,
    otp2,
    otp3,
    otp4,
    otp5,
    otp1InputRef,
    otp2InputRef,
    otp3InputRef,
    otp4InputRef,
    otp5InputRef,
    otp1valid,
    otp2valid,
    otp3valid,
    otp4valid,
    handleEnrolnoChange,
    
  };
};

export default useLoginHooks;
