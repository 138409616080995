import React from "react"
import { useNavigate } from "react-router-dom";
import { alertActions, userLogout } from "../../../actions";
import { useEffect, useState } from 'react'
import HomeScreen from "../Home/HomeScreen";

const usePopupHook = (t) => {
    const navigate = useNavigate();
    const handleClose = () => {
        navigate('/');
    }

    return {
        handleClose
       
    };
};

export default usePopupHook;