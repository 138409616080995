import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { alertActions, userLogout } from "../../../actions";
import React, { useEffect, useState } from 'react'
import { HomeService } from '../../../services/HomeService'
import { use } from "i18next";
import moment from "moment";
import { Alert } from "bootstrap";

const useHomeScreenHook = (t) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [IsOpen, SetIsOpen] = useState(false);
  // const [show, SetShow] = useState(false);
  const [data, setData] = useState("");
  const [value, onChange] = useState(new Date());

  //state for fields
  const [requestorName, setRequestorName] = useState(localStorage.getItem("name"));
  const [empCode, setEmpcode] = useState(localStorage.getItem("enrollment_number"));
  const [requestorAddress, setRequestorAddress] = useState("");
  const [requestorContact, setRequestorContact] = useState(localStorage.getItem("phone_no"));
  const [guestName, setGuestName] = useState("");
  const [guestAddress, setGuestAddress] = useState("");
  const [guestContact, setGuestContact] = useState("");
  const [guestRelationship, setGuestRelationship] = useState("");
  const [arrivalDate, setArrivalDate] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [departureDate, setDepartureDate] = useState("");
  const [departureTime, setDepartureTime] = useState("");
  const [numOfDays, setNumOfDays] = useState("");
  const [numOfRoom, setNumOfRooms] = useState("");
  const [numOfGuest, setNumOfGuests] = useState("");
  const [purposeOfVisit, setPurposeOfVisit] = useState("");
  const [selectedFile,setSelectedFile]= useState("");
  const [acceptCheck, setAcceptCheck] = useState(false);
  const [show, SetShow] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [datetimevalue, setdatetimevalue] = useState();
  const [datetimevalue1, setdatetimevalue1] = useState();
  const [institution, setInstitution] = useState(localStorage.getItem("institution"));
  const [email, setEmail] = useState("");
  const [department, setDepartment] = useState(localStorage.getItem("department"));
  const[response,setResponse]=useState("");
  const [file, setFile] = useState();
  //image states
  const [image, setImage] = useState(null);
  const [role_type, setRoletype]=useState("");
  const role_type_get =  localStorage.getItem("role_type");
  ///state for  GST form 
  const [payload, setPayload] = useState({
    includeGst: 0,
    gst: {
      gstNumber: "",
      gstCompany: "",
      gstAddress: ""
    }
  });
  useEffect(() => {
    // setdatetimevalue("");
    // setdatetimevalue1("");
    // localStorage.removeItem("datetime1")
    // localStorage.removeItem("datetime2")
    // handleArrivalDate();
    // handleDepartureDate();
  }, [IsOpen])
  const { includeGst, gst } = payload;
  const gstOnchange = (values) => {
    setPayload({
      ...payload,
      ...values
    });
  };

  const onChangeImageHandler = (e) => 
  {
    if (!e.target.files || e.target.files.length === 0) {
      setImage(null)
      return
    }
    setSelectedFile(e.target.files[0]);
    setImage(e.target.files[0])
  }


  const UploadHandler = () => {
    SetShow(true)
    // SetShow(e.target.value)
  }
  //Handle submit form
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true)
    // SetShow(false)
    // var relationType = /^[a-zA-Z]*$/;
    var filter = /^[0-9]{10}$/;
    // var guestContactfilter = /"^[0-9]*$"/;
    var emailTest = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    setAcceptCheck(false)
    if (numOfDays < 0) {
      dispatch(alertActions.error("Enter valid number of days"));
    }
    else if (new Date(datetimevalue) >= new Date(datetimevalue1)) {
      dispatch(alertActions.error("Invalid Departure Date and Time"));
    }
    else if (gst?.gstNumber != "" && gst?.gstNumber.length > 15) {
      dispatch(alertActions.error("Enter valid GST number"));
    }
     else if (requestorContact && !filter.test(requestorContact)) {
      dispatch(alertActions.error("Enter valid Requestor Contact Number"));
    } 
    else if (email && !emailTest.test(email)) {
      dispatch(alertActions.error("Enter valid email address"))
    }
    // else if (guestRelationship && !relationType.test(guestRelationship)) {
    //   dispatch(alertActions.error("Enter valid guest relationship"))
    // }
    else if (guestContact && !filter.test(guestContact)) {
      dispatch(alertActions.error("Enter valid Guest Contact Number"));
    } else {
      if (
        requestorName &&
        // empCode &&
        // requestorAddress &&
        requestorContact &&
        guestName &&
        // guestAddress &&
        guestContact &&
        // guestRelationship &&
        arrivalDate &&
        arrivalTime &&
        departureDate &&
        departureTime 
        // &&
        // image
        // numOfDays &&
        // purposeOfVisit
        // includeGst &&
        // gst.gstNumber
        // gst.gstAddress &&
        // gst.gstCompany
      ) {
        HomeService.addRequestorDetails(
          requestorName,
          institution,
          email,
          empCode,
          requestorAddress,
          requestorContact,
          guestName,
          guestAddress,
          guestContact,
          guestRelationship,
          arrivalDate,
          arrivalTime,
          departureDate,
          departureTime,
          numOfDays,
          numOfRoom,
          numOfGuest,
          purposeOfVisit,
          includeGst,
          gst?.gstNumber,
          gst?.gstAddress,
          gst?.gstCompany,
          image,
          localStorage.getItem("institution_master_id"),
          localStorage.getItem("degree_master_id"),
          localStorage.getItem("course_master_id"),
          localStorage.getItem("degree"),
          localStorage.getItem("course"),
          localStorage.getItem("role_type"), 
          localStorage.getItem("department"),
          localStorage.getItem("department_master_id")).then((response) => {
          if (response.data.status.code === 0) {
           console.log(response.data);
            setResponse(response.data);
            setSubmitted(false)
            setSelectedFile("")
            SetIsOpen(true)
            setAcceptCheck(true)
            setImage(null)
            SetShow(false)
            setPayload("")
            setRequestorName("")
            setInstitution("")
            setEmail("")
            setEmpcode("")
            setRequestorAddress("")
            setRequestorContact("")
            setGuestName("")
            setGuestAddress("")
            setGuestContact("")
            setGuestRelationship("")
            setArrivalDate("")
            setArrivalTime("")
            setDepartment("")
            setDepartureDate("")
            setDepartureTime("")
            setNumOfDays("")
            setNumOfRooms("")
            setNumOfGuests("")
            setPurposeOfVisit("")
            localStorage.clear();
            dispatch(alertActions.success(response.data.status.message));
            setTimeout(
              () => (dispatch(alertActions.clear())),
              2000
            );
            setSubmitted(false)
          }
        })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
      // }
    }
  }
  //handle change for all feilds
  const handleInstitution = (e) => {
    setInstitution(e.target.value);
  }
  const handleDepartment = (e) => {
    setDepartment(e.target.value);
  }
  const handleEmail = (e) => {
    setEmail(e.target.value);
  }
  const handleRequestorChange = (e) => {
    setRequestorName(e.target.value);
  }
  const handleEmpcode = (e) => {
    setEmpcode(e.target.value);
  }
  const handleRequestoAddress = (e) => {
    setRequestorAddress(e.target.value);
  }
  const handleRequestorContact = (e) => {
    setRequestorContact(e.target.value);
  }
  const handleGuestName = (e) => {
    const reslt = e.target.value.replace(/[^a-zA-Z]/ig, ' ');
    setGuestName(reslt);
  }
  const handleGuestAddress = (e) => {
    setGuestAddress(e.target.value);
  }
  const handleGuestContact = (e) => {
    const result = e.target.value.replace(/\D/g, ' ');
    setGuestContact(result);
  }
  const handleGuestRelationship = (e) => {
    const result = e.target.value.replace(/[^a-zA-Z]/ig, ' ');
    setGuestRelationship(result);
  }
  // const handleArrivalDate = (e) => {
  //   setArrivalDate(e.target.value)
  // }
  const handleArrivalDate = (e) => {
      localStorage.setItem("datetime1", e);
      setdatetimevalue(
      localStorage.getItem("datetime1")
    );
    setArrivalDate(moment(e).format("YYYY-MM-DD"));
    setArrivalTime(moment(e).format("h:mm A"));
    // convertArrivalTime(e);
  };
  // const handleDepartureDate = (e) => {
  //   setDepartureDate(e.target.value)
  // }
  const handleDepartureDate = (e) => {
    localStorage.setItem("datetime2", e);
    setdatetimevalue1(
      localStorage.getItem("datetime2")
    );
    setDepartureDate(moment(e).format("YYYY-MM-DD"));
    setDepartureTime(moment(e).format("h:mm A"));
  }
  const handleNumOfDays = (e) => {
    const res = e.target.value.replace(/\D/g, '');
    setNumOfDays(res);
  }
  const handleNumOfRooms = (e) => {
    const res = e.target.value.replace(/\D/g, '');
    setNumOfRooms(res);
  }
  const handleNumOfGuests = (e) => {
    const res = e.target.value.replace(/\D/g, '');
    setNumOfGuests(res);
  }
  const handlePurposeOfVisit = (e) => {
    setPurposeOfVisit(e.target.value)
  }
  ///*************/

  const togglePopup = () => {
    SetIsOpen(!IsOpen);
    window.location.reload(true)
    localStorage.removeItem("datetime1")
    localStorage.removeItem("datetime2")
  }
  /**
   * handle logout
   */
  const handleLogout = () => {
    dispatch(alertActions.success("Logout Success"));
    setTimeout(() => {
      dispatch(userLogout());
      navigate("/");
    }, 100);
  };

  return {
    response,
    value,
    onChange,
    handleLogout,
    togglePopup,
    UploadHandler,
    // formHandler,
    show,
    role_type,
    selectedFile,
    requestorName,
    empCode,
    requestorAddress,
    requestorContact,
    guestName,
    guestAddress,
    guestContact,
    guestRelationship,
    arrivalDate,
    arrivalTime,
    departureDate,
    departureTime,
    numOfDays,
    numOfRoom,
    numOfGuest,
    purposeOfVisit,
    includeGst,
    datetimevalue,
    datetimevalue1,
    submitted,
    institution,
    department,
    email,
    handleSubmit,
    handleInstitution,
    handleEmail,
    handleRequestorChange,
    handleEmpcode,
    handleRequestoAddress,
    handleRequestorContact,
    handleGuestName,
    handleGuestAddress,
    handleGuestContact,
    handleGuestRelationship,
    handleArrivalDate,
    handleDepartureDate,
    handleNumOfDays,
    handlePurposeOfVisit,
    gstOnchange,
    onChangeImageHandler,
    handleDepartment,
    handleNumOfRooms,
    handleNumOfGuests,
    gst,
    IsOpen,
    image,
    role_type_get
  };
};

export default useHomeScreenHook;