import React, { useState } from 'react'
import './header.css'
import '../../pages/note/note'
import Note from '../../pages/note/note';
import logo from '../../../assets/images/logo.png'
import icon from '../../../assets/images/icon.png'
import logout from '../../../assets/images/LOGOUT.png'
import useHeaderHooks from "./useHeaderHooks";
import { logoo, iconn } from "../../../assets/images/images"


function Header() {
    const { IsOpen, toggleNote, logoutHandler } = useHeaderHooks();



    return (
        <>

            <div class="main_container flt ">

                <div class="header flt">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="logo flt">
                                <img src={logoo}></img>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="header_text flt">
                                <button title='Note' onClick={toggleNote}><img className='logo flt' src={iconn} /> </button>
                                <button title='Logout' onClick={logoutHandler}><img className='logout flt' src={logout} /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            {IsOpen && <Note handleClose={toggleNote} />}
        </>
    )
}

export default Header